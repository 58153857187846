<template>
<div class="mt-n12 ">

    <v-layout row  justify-space-between :class="$vuetify.breakpoint.lgAndUp?'ml-12 pl-12 mr-12' : 'ma-3 mt-10 ' ">
        <v-layout justify-center  column :style="$vuetify.breakpoint.mdAndUp?'width:40%':'width:90%'" class="mt-10" >
            <h1 :style="$vuetify.breakpoint.mdAndUp?'font-size:36px; text-align: left;': 'font-size:36px; text-align: center;'">Accelarate Innovation, Reduce Costs</h1><br>
            <p style="font-size: 20px; " :style="$vuetify.breakpoint.mdAndUp?'width: 80%;': 'text-align: justify;text-justify: inter-word;'">Build secure, performant, scalable and engaging digital experiences cost effectively with ready to use API’s and datasets.  </p>
            <br>
            <v-btn color="primary" width="150" height="50" @click="gotoSignup" v-show="!loggedIn" :style="$vuetify.breakpoint.mdAndUp?'':'margin:auto;display:block;'">Sign-Up</v-btn>
        
        </v-layout>
        <v-layout justify-end style="width:50%;overflow: hidden;position:relative;" class=" mt-12 pt-5" v-show="$vuetify.breakpoint.mdAndUp" :class="$vuetify.breakpoint.lgAndUp?'mr-n12' : 'mr-n3 ' " >
        <!-- <v-img src="@/assets/home.svg" contain width="90%"  aspect-ratio="1" style="float:right" ></v-img>  -->
        <!-- <div id="box"></div> -->
        <globe ></globe>

        </v-layout>

         <v-card rounded="lg" class=" mb-12 mt-12" :class="$vuetify.breakpoint.lgAndUp?'mr-12 ': ''" style="width:100%; height: auto; overflow: hidden" elevation="4">
          <v-row>
            <v-col cols="5" v-show="$vuetify.breakpoint.lgAndUp">
              <div style="width:100%;height: 100%">
              <lazyImage :src="require('../assets/home2.svg')" cover  ></lazyImage>
              </div>
            </v-col>
            <v-col cols="11" lg="6" class="ml-2 mt-3 pa-5 pr-1" >
              <h2 style="font-size: 36px;" :style="$vuetify.breakpoint.mdAndUp?'': 'text-align: center;'">We are here to help!</h2><br>
              <p style="font-size: 18px; font-weight: 400; overflow: wrap;  " :style="$vuetify.breakpoint.mdAndUp?'': ''" class="mt-n4">API short for Application Programming Interface make it easy to create beautiful applications, digital experiences and solve problems by hiding all complex computations, processes, integrations and large data management.</p><br>
              <p style="font-size: 18px; font-weight: 400; overflow: wrap;  " class="mt-n4">Creating good and beautiful API's which hides the internal details to provide useful and consistent parts to programmers even when the internal details keep changing is a huge undertaking.</p> <br>
              <p style="font-size: 18px; font-weight: 400; overflow: wrap;  " class="mt-n4">We at apijug are happy to take care of your api needs, while you focus on creating beautiful applications for your customers.</p> 


              <p style="font-size: 18px; font-weight: 600; text-decoration: underline " class="primary--text mt-5">Explore products</p>
            </v-col>
          </v-row>
        </v-card>

        <v-layout justify-center :class="$vuetify.breakpoint.lgAndUp?'mr-12 ': ''">
          <div style="width:70%" class="mt-12 mb-12">
            <v-row justify="center">
             <v-col cols="6" lg="3" sm='5' md='4'  class="mt-2 mb-10">
               <v-layout justify-center column>
                 <v-icon size="84" color="primary">bolt</v-icon>
                 <p style="font-size: 18px; text-align:center" class="mt-2">Fast</p>
               </v-layout>
             </v-col>
              <v-col cols="6" lg="3" sm='5' md='4' class="mt-2 mb-10">
               <v-layout justify-center column>
                 <v-icon size="84" color="primary">security</v-icon>
                 <p style="font-size: 18px; text-align:center" class="mt-2">Secure</p>
               </v-layout>
             </v-col>
              <v-col cols="6" lg="3" sm='5' md='4' class="mt-2 mb-10">
               <v-layout justify-center column>
                 <v-icon size="84" color="primary">local_florist</v-icon>
                 <p style="font-size: 18px; text-align:center" class="mt-2">Cost Effecient</p>
               </v-layout>
             </v-col>
              <v-col cols="6" lg="3" sm='5' md='4' class="mt-2 mb-10">
               <v-layout justify-center column>
                 <v-icon size="84" color="primary">devices_other</v-icon>
                 <p style="font-size: 18px; text-align:center" class="mt-2">Time Management</p>
               </v-layout>
             </v-col>
              <v-col cols="6" lg="3" sm='5' md='4' class="mt-2 mb-10">
               <v-layout justify-center column>
                 <v-icon size="84" color="primary">apartment</v-icon>
                 <p style="font-size: 18px; text-align:center" class="mt-2">Scalable</p>
               </v-layout>
             </v-col>
              <v-col cols="6" lg="3" sm='5' md='4' class="mt-2 mb-10">
               <v-layout justify-center column>
                 <v-icon size="84" color="primary">thumb_up_alt</v-icon>
                 <p style="font-size: 18px; text-align:center" class="mt-2">High Quality</p>
               </v-layout>
             </v-col>
              <v-col cols="6" lg="3" sm='5' md='4' class="mt-2 mb-10">
               <v-layout justify-center column>
                 <v-icon size="84" color="primary">vpn_lock</v-icon>
                 <p style="font-size: 18px; text-align:center" class="mt-2">Future Ready</p>
               </v-layout>
             </v-col>
            </v-row>
          </div>
        </v-layout>

    <!-- <v-layout justify-space-between class=" mb-12 mt-5 mr-12" :class="$vuetify.breakpoint.lgAndUp?'mr-12': ''"  v-if="$vuetify.breakpoint.mdAndUp"  >
      <v-card rounded="lg" class="feature_card1  secondary_white mr-6 " elevation="4"  >              
        <h2 style="font-size: 28px;">All in one subscription</h2><br>
        <p style="font-size: 18px; font-weight: 400;" class="mt-n4">Lorem ipsum dolor sit amet, consectetuamet arcu. Aenean ac ipsum turpis. Cras fringilla dolor diam, in semper eros ullamcorper sed. Maecenas libero sapien, ultricies quis imperdiet at, mattis sit amet arcu.</p><br>

        <p style="font-size: 18px; font-weight: 400; " class="mt-n4">Aliquam et lectus consectetur, congue sem nec, eleifend risus. Aliquam et lectus consectetur, congue sem nec, eleifend risus. Aliquam et lectus consectetur, congue sem nec, eleifend risus.</p>

      </v-card>
      <v-card rounded="lg" class="feature_card2 secondary_white ml-6 " elevation="4">
         <h2 style="font-size: 28px;">Fully self serviced API</h2><br>
        <p style="font-size: 18px; font-weight: 400;" class="mt-n4">Lorem ipsum dolor sit amet, consectetuamet arcu. Aenean ac ipsum turpis. Cras fringilla dolor diam, in semper eros ullamcorper sed. Maecenas libero sapien, ultricies quis imperdiet at, mattis sit amet arcu.</p><br>

        <p style="font-size: 18px; font-weight: 400; " class="mt-n4">Aliquam et lectus consectetur, congue sem nec, eleifend risus. Aliquam et lectus consectetur, congue sem nec, eleifend risus. Aliquam et lectus consectetur, congue sem nec, eleifend risus.</p>

        
      </v-card>
    </v-layout>


    <v-layout justify-center class=" mb-12 mt-5 mr-2"  v-else column   >
      <v-card rounded="lg" class="feature_card1  secondary_white " elevation="4" >              
        <h2 style="font-size: 28px;">All in one subscription</h2><br>
        <p style="font-size: 18px; font-weight: 400;" class="mt-n4">Lorem ipsum dolor sit amet, consectetuamet arcu. Aenean ac ipsum turpis. Cras fringilla dolor diam, in semper eros ullamcorper sed. Maecenas libero sapien, ultricies quis imperdiet at, mattis sit amet arcu.</p><br>

        <p style="font-size: 18px; font-weight: 400; " class="mt-n4">Aliquam et lectus consectetur, congue sem nec, eleifend risus. Aliquam et lectus consectetur, congue sem nec, eleifend risus. Aliquam et lectus consectetur, congue sem nec, eleifend risus.</p>

      </v-card>
      <v-card rounded="lg" class="feature_card2 secondary_white mt-5 " elevation="4">
         <h2 style="font-size: 28px;">Fully self serviced API</h2><br>
        <p style="font-size: 18px; font-weight: 400;" class="mt-n4">Lorem ipsum dolor sit amet, consectetuamet arcu. Aenean ac ipsum turpis. Cras fringilla dolor diam, in semper eros ullamcorper sed. Maecenas libero sapien, ultricies quis imperdiet at, mattis sit amet arcu.</p><br>

        <p style="font-size: 18px; font-weight: 400; " class="mt-n4">Aliquam et lectus consectetur, congue sem nec, eleifend risus. Aliquam et lectus consectetur, congue sem nec, eleifend risus. Aliquam et lectus consectetur, congue sem nec, eleifend risus.</p>

        
      </v-card>
    </v-layout>

     -->
    </v-layout>

    <v-card class=" secondary_white fullCover mt-5  pa-10 mb-10 "  > 
      <v-row>
        <v-col cols="12" lg="6">
          <h2 style="font-size: 28px;">Try it out now</h2>
          <h1 style="font-size: 36px; color:#828282; margin-left: 60px  " class=" mt-n2">Designed For Developers</h1><br>

        </v-col>
        <v-col cols="6">
          <div></div>
        </v-col>
      </v-row>

    </v-card>

</div>
  
</template>

<script>
const lazyImage = () => import('../components/lazyImage.vue')
import globe from '../components/globe.vue'
export default {

  components: {
    globe,
    lazyImage,
  },

computed: {

    loggedIn: function(){
      return this.$store.state.auth.isLoggedIn;
    }
  },

methods: {
    gotoSignup: function(){
      this.$router.push({ name : 'signup'})
    },

    
}

}
</script>

<style>

.fullCover{
  width: 100%
}

.imgi {  
    background: url('../assets/home2.svg') no-repeat;
    background-size: 100% 100%;
}

.feature_card1{
  width: 99%;
  background-image: url('../assets/home4.svg');
  background-position: right 30px bottom 30px;
  padding: 30px 30px 120px 30px;

}

.feature_card2{
  width: 99%;
  background-image: url('../assets/home3.svg');
  background-position: right 0px bottom 0px;
  padding: 30px 30px 120px 30px;
}


#box {
  /* background:url(https://i.imgur.com/bYSnuNE.jpg?1); */
  background:url(https://i.imgur.com/LIRDh.jpeg);
  /* background: url(https://cdn.pixabay.com/photo/2015/09/30/08/25/money-965060_1280.jpg); */
  /* background: url(https://mpng.subpng.com/20190407/rau/kisspng-world-map-illustration-vector-graphics-5caa53dc9bfa54.8343029515546664606389.jpg); */
  background-size:cover;
  /*border:2px solid #9ecaed; */
  box-shadow: 0 0 15px #9ecaed;
  border-radius:50%;
  width:650px;
  height:650px;
  animation: movimiento 10s linear 0s infinite;
  box-shadow:0 0 25px RGBA(255,255,255, 0.10),
             -8px -8px 15px  #9ecaed,
             2px 2px 25px #000000 inset,
             -45px -45px 25px RGBA(0,0,0, 0.5) inset, 
             25px 25px 45px RGBA(0,0,0, 0.45) inset;
  /* margin:6em auto; */
  right: -170px;
  position:relative;
  transform:rotateX(6deg) rotateY(6deg) rotateZ(6deg);
}

@keyframes movimiento {
  0% { background-position:0 0 }
  100% { background-position:355px 0 }
}

@keyframes stars {
  0% { background-position:0 0 }
  100% { background-position:0 100% }
}

</style>
