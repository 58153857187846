<template>
    <div id="globeViz" ref="canvas" style="background: #ffffff; margin-top: -50px" :style="$vuetify.breakpoint.lgAndUp?'margin-right: -400px;' : 'margin-right: -400px;'"></div>

</template>

<script>
import ThreeGlobe from 'three-globe/dist/three-globe.min.js';
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'


//import TrackballControls from 'three-trackballcontrols'

export default {

    data: () => ({
        globe: {},
        tbControls: null,
        renderer: {},
        scene: {},
        camera: {},

    }),

    created: function(){
        const N = 10;
    const gData = [...Array(N).keys()].map(() => ({
      lat: (Math.random() - 0.5) * 180,
      lng: (Math.random() - 0.5) * 360,
      maxR: Math.random() * 20 + 3,
      propagationSpeed: (Math.random() - 0.5) * 20 + 1,
      repeatPeriod: Math.random() * 2000 + 200
    }));

    const colorInterpolator = t => `rgba(255,100,50,${1-t})`;
    // const globeImage = require("../assets/globe.jpg")

    const Globe = new ThreeGlobe()
      .globeImageUrl('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg')
      .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
      .ringsData(gData)
      .ringColor(() => colorInterpolator)
      .ringMaxRadius('maxR')
      .ringPropagationSpeed('propagationSpeed')
      .ringRepeatPeriod('repeatPeriod');

    // Setup renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true});
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setClearColor( 0xffffff, 1 ); // the second parameter is opacity

    // document.getElementById('globeViz').appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera();
    this.camera.aspect = window.innerWidth/window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.camera.position.z = 300;
    

    // Setup scene
    this.scene = new THREE.Scene();
    this.scene.add(Globe);
    this.scene.add(new THREE.AmbientLight(0xbbbbbb));
    //this.scene.add(new THREE.DirectionalLight(0x2196F3, 0.6));

    const light = new THREE.DirectionalLight( 0xffffff, 0.6, 100 );
    light.position.set( 0, 1, 0 ); //default; light shining from top
    light.castShadow = true; // default false
    this.scene.add( light );
    this.scene.add(this.camera)

    // Setup camera
    
    
    // Add camera controls
    this.tbControls = new OrbitControls(this.camera, this.renderer.domElement);
    this.tbControls.enableZoom = false
    this.tbControls.autoRotate = true
    this.tbControls.autoRotateSpeed = 1.5
    this.tbControls.update();

  

    },

    mounted: function(){
   this.$refs.canvas.appendChild(this.renderer.domElement);
    this.animate()


    },

    methods:{
        animate: function(){
        this.tbControls.update();
        this.renderer.render(this.scene, this.camera);
        requestAnimationFrame(this.animate);
        },

       

    }

}
</script>

<style>

</style>